import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { NumberExistsResult, PhoneNumber } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  phoneNumbersTableview(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<PhoneNumber>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const url = `https://${baseUrl()}/phoneNumbers?${query}`
    return httpService.get(url)
  },
  getPhoneNumber(id: number): Promise<AxiosResponse<PhoneNumber>> {
    const url = `https://${baseUrl()}/phoneNumbers/${id}`
    return httpService.get(url)
  },
  createPhoneNumber(payload: PhoneNumber): Promise<AxiosResponse<PhoneNumber>>  {
    const url = `https://${baseUrl()}/phoneNumbers`
    return httpService.post(url, payload)
  },
  updatePhoneNumber(payload: PhoneNumber): Promise<AxiosResponse<PhoneNumber>>  {
    const url = `https://${baseUrl()}/phoneNumbers/${payload.phoneNumberId}`
    return httpService.post(url, payload)
  },
  checkIfPhoneNumberExists(phoneNumber: string): Promise<AxiosResponse<NumberExistsResult>>  {
    const url = `https://${baseUrl()}/phoneNumbers/${phoneNumber}/check`
    return httpService.get(url)
  },
}
